import { PropsWithChildren, createContext, useState } from "react";
import { TypeDiagnosaContext } from "../utils/types";
import axios from "axios";
import { FAILED_DATA } from "../utils/constants.tsx";
import { host } from "./config";

axios.defaults.withCredentials = true;
const initialState: TypeDiagnosaContext = {
  resultSearchDiagnosaList: [],
  paginationSearch: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isLoadingSearch: false,
};
export const DiagnosaContext = createContext<TypeDiagnosaContext>(initialState);
const DiagnosaState: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<TypeDiagnosaContext>(initialState);
  const dispatch = (payload: Partial<TypeDiagnosaContext>) => {
    setState((prevState) => ({
      ...prevState,
      ...payload,
    }));
  };

  const searchDiagnosa = async (data: { page?: number; query?: string }) => {
    dispatch({ isLoadingSearch: true });
    try {
      const res = await axios.get(host + "/diagnosa/get_diagnosa.php", {
        params: data,
      });
      if (res.data.success) {
        dispatch({
          resultSearchDiagnosaList: res.data.data || [],
          paginationSearch: res.data.pagination,
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingSearch: false });
    }
  };

  return (
    <DiagnosaContext.Provider
      value={{
        ...state,
        searchDiagnosa,
      }}
    >
      {children}
    </DiagnosaContext.Provider>
  );
};

export default DiagnosaState;
