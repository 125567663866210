import { PropsWithChildren, createContext, useState } from "react";
import { TypePerawatan, TypePerawatanContext } from "../utils/types";
import axios from "axios";
import { FAILED_DATA } from "../utils/constants.tsx";
import { host } from "./config";

axios.defaults.withCredentials = true;
const initialState: TypePerawatanContext = {
  perawatanList: [],
  allPerawatanList: [],
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isLoadingGet: false,

  kodePerawatanList: [],
  paginationKodePerawatan: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  isLoadingKodePerawatan: false,
};
export const PerawatanContext =
  createContext<TypePerawatanContext>(initialState);
const PerawatanState: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<TypePerawatanContext>(initialState);
  const dispatch = (payload: Partial<TypePerawatanContext>) => {
    setState((prevState) => ({
      ...prevState,
      ...payload,
    }));
  };

  const getPerawatan = async (data: {
    page?: number;
    query?: string;
    sort_field?: string;
    is_asc?: boolean;
  }) => {
    dispatch({ isLoadingGet: true });
    try {
      const res = await axios.get(host + "/perawatan/get_perawatan.php", {
        params: data,
      });
      if (res.data.success) {
        dispatch({
          perawatanList: res.data.data || [],
          pagination: res.data.pagination,
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingGet: false });
    }
  };

  const getAllPerawatan = async () => {
    dispatch({ isLoadingGet: true });
    try {
      const res = await axios.get(host + "/perawatan/get_all_perawatan.php");
      if (res.data.success) {
        dispatch({
          allPerawatanList: res.data.data || [],
        });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      dispatch({ isLoadingGet: false });
    }
  };

  const addPerawatan = async (data: TypePerawatan) => {
    try {
      const res = await axios.post(host + "/perawatan/add_perawatan.php", data);
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const editPerawatan = async (data: Partial<TypePerawatan>) => {
    try {
      const res = await axios.post(
        host + "/perawatan/edit_perawatan.php",
        data
      );

      const prevIndex = state.perawatanList.findIndex(
        (item) => item.id === data.id
      );
      if (prevIndex !== -1) {
        const newPerawatanList = [...state.perawatanList];
        newPerawatanList[prevIndex] = {
          ...newPerawatanList[prevIndex],
          ...data,
        };
        dispatch({ perawatanList: newPerawatanList });
      }

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const deletePerawatan = async (id: number) => {
    try {
      const res = await axios.post(host + "/perawatan/delete_perawatan.php", {
        id,
      });

      const prevIndex = state.perawatanList.findIndex((item) => item.id === id);
      if (prevIndex !== -1) {
        const newPerawatanList = [...state.perawatanList];
        newPerawatanList.splice(prevIndex, 1);
        dispatch({ perawatanList: newPerawatanList });
      }

      return res.data;
    } catch (err) {
      return FAILED_DATA;
    }
  };

  const getKodePerawatan = async (data: { page?: number; query?: string }) => {
    // dispatch({ isLoadingKodePerawatan: true });
    try {
      const res = await axios.get(host + "/perawatan/get_kode_perawatan.php", {
        params: data,
      });
      if (res.data.success) {
        // dispatch({
        //   kodePerawatanList: res.data.data || [],
        //   paginationKodePerawatan: res.data.pagination,
        // });
      }
      return res.data;
    } catch (err) {
      return FAILED_DATA;
    } finally {
      // dispatch({ isLoadingKodePerawatan: false });
    }
  };

  return (
    <PerawatanContext.Provider
      value={{
        ...state,
        getPerawatan,
        getAllPerawatan,
        addPerawatan,
        editPerawatan,
        deletePerawatan,
        getKodePerawatan,
      }}
    >
      {children}
    </PerawatanContext.Provider>
  );
};

export default PerawatanState;
