import { TypeDispatch } from "./types";
import { GrTopCorner, GrBottomCorner } from "react-icons/gr";
import { HiOutlineMinus } from "react-icons/hi";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { IoReturnUpForwardOutline, IoReturnDownForward } from "react-icons/io5";

export const FAILED_DISPLAY_MESSAGE = "Coba beberapa saat lagi";
export const REQUIRED_MESSAGE = "Wajib diisi";
export const SORTER_TOOLTIP = "Urutkan data";
export const EMPTY_DATA = "Data tidak ditemukan";
export const numberColumns = [
  "no_hp",
  "tensi_1",
  "tensi_2",
  "nadi",
  "tinggi_badan",
];
export const floatColumns = ["suhu", "berat_badan"];
export const dateColumns = ["tgl_lahir", "tgl_periksa"];
export const dateTimeColumns = [
  "tgl_antrean",
  "tgl_diperiksa",
  "tgl_selesai_diperiksa",
];
export const radioColumns = ["jenis_kelamin", "role", "status"];
export const textareaColumns = [
  "alamat",
  "keluhan",
  "diagnosa",
  "keterangan",
  "riwayat_penyakit",
  "riwayat_alergi",
];
export const passwordColumns = ["password"];
export const priceColumns = ["harga"];
export const noSpaceColumns = ["username"];
export const mappedColumns = [
  {
    name: "perawatan",
    attributeName: "nama_perawatan",
    attributeJumlah: "jumlah",
  },
  {
    name: "dokumen_pendukung",
    attributeName: "nama",
  },
];
export const DATE_FORMAT = "DD-MM-YYYY";
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const SIZE_COLUMN_XS = 60;
export const SIZE_COLUMN_SM = 80;
export const SIZE_COLUMN_MD = 100;
export const SIZE_COLUMN_LG = 120;
export const SIZE_COLUMN_XL = 150;
export const GENDER_OPTIONS = [
  { label: "Laki-laki", value: "L" },
  { label: "Perempuan", value: "P" },
];
export const STATUS_OPTIONS = [
  { label: "Menunggu", value: 0 },
  { label: "Diperiksa", value: 1 },
];
export const GIGI_KONDISI_UMUM = [
  { label: "Uneven (Tidak Rata)", value: "une" },
  { label: "Pre-existing (Kondisi yang Sudah Ada Sebelumnya)", value: "pre" },
  { label: "Anomaly (Anomali)", value: "ano" },
  { label: "Fractured (Gigi Patah)", value: "fractured" },
  { label: "Tidak ada", value: "" },
];
export const GIGI_PERAWATAN_MAHKOTA = [
  { label: "Mahkota Logam (Logam Crown)", value: "mahkotaLogam" },
  { label: "Mahkota Non-Logam (Non-Metal Crown)", value: "mahkotaNonLogam" },
  { label: "Tidak ada", value: "" },
];
export const GIGI_JEMBATAN = [
  { label: GrTopCorner, value: "kiri" },
  { label: HiOutlineMinus, value: "tengah" },
  { label: GrBottomCorner, value: "kanan" },
  { label: "Tidak ada", value: "" },
];
export const GIGI_MIGRASI = [
  { label: HiArrowLongLeft, value: "kiri" },
  { label: HiArrowLongRight, value: "kanan" },
  { label: IoReturnUpForwardOutline, value: "atas" },
  { label: IoReturnDownForward, value: "bawah" },
  { label: "Tidak ada", value: "" },
];
export const GIGI_KONDISI_LAINNYA = [
  { label: "Sisa Akar (Root Remnant)", value: "sisaAkar" },
  { label: "Gigi Hilang (Missing Tooth)", value: "gigiHilang" },
  { label: "Non-Vital (Gigi Non-Vital)", value: "nonVital" },
  {
    label: "Perawatan Saluran Akar (Root Canal Treatment)",
    value: "perawatanSalAkar",
  },
  {
    label: "Lainnya (tulis di keterangan)",
    value: "lainnya",
  },
];

export const GIGI_KONDISI_SPESIFIK = [
  { label: "Caries (Karies)", value: "caries" },
  { label: "Tambalan Logam (Logam)", value: "tambalanLogam" },
  { label: "Tambalan Non-Logam (Non-Logam)", value: "tambalanNonLogam" },
  { label: "Tidak ada", value: "" },
];

export const ROLE_OPTIONS = [
  { label: "Admin", value: "ADMIN" },
  { label: "Superadmin", value: "SUPERADMIN" },
];
export const FAILED_DATA: TypeDispatch<any> = {
  success: false,
  data: null,
  displayMessage: FAILED_DISPLAY_MESSAGE,
};
