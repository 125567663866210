import { useContext, useEffect, useState } from "react";
import { Select } from "antd";
import { GlobalContext } from "../contexts/GlobalState";
import { FAILED_DISPLAY_MESSAGE } from "../utils/constants";
import { TypeDispatch, TypePagination } from "../utils/types";
import { LoadingSpinner } from "../pages/Home";

interface SelectWithApiProps<TData> {
  fetchOptions: (query: string, page: number) => Promise<TypeDispatch<TData>>;
  transformData: (data: TData) => { label: string; value: string }[];
  initialOptions: { label: string; value: string }[];
  onChange?: (value: any) => void;
  value?: any;
  placeholder?: string;
}

const SelectWithApi = <TData,>({
  fetchOptions,
  transformData,
  initialOptions,
  onChange,
  value,
  placeholder,
}: SelectWithApiProps<TData>) => {
  const [pagination, setPagination] = useState<TypePagination>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isUserTyping, setIsUserTyping] = useState(true);
  const { showErrorMessage } = useContext(GlobalContext);

  const getOptions = async (page: number) => {
    setLoading(true);
    const res = await fetchOptions(searchValue, page);
    if (res.success) {
      setPagination({ ...res.pagination });
      const newOptions = transformData(res.data);
      if (page === 1) {
        setOptions(newOptions);
      } else {
        setOptions((prevOptions) => [...prevOptions, ...newOptions]);
      }
    } else {
      const messageKey = new Date().toISOString();
      showErrorMessage(
        messageKey,
        res?.displayMessage || FAILED_DISPLAY_MESSAGE
      );
      setOptions([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isUserTyping) {
      getOptions(1);
    }
  }, [searchValue]);

  const shownOptions =
    options.length || searchValue !== "" ? options : initialOptions;

  useEffect(() => {
    if (
      value &&
      !shownOptions.find((item) => parseInt(item.value) === parseInt(value))
    ) {
      onChange(null);
    }
  }, []);
  return (
    <Select
      showSearch
      allowClear
      searchValue={searchValue}
      onSearch={(inputValue) => {
        setSearchValue(inputValue);
        if (inputValue !== "") setIsUserTyping(true);
      }}
      filterOption={false}
      options={shownOptions}
      onChange={(prop) => {
        setIsUserTyping(false);
        onChange(prop);
      }}
      value={value}
      placeholder={placeholder}
      loading={loading}
      className="w-full"
      dropdownRender={(menu) => {
        return (
          <div>
            {menu}
            {loading ? (
              <div style={{ textAlign: "center", padding: 8 }}>
                <LoadingSpinner />
              </div>
            ) : pagination.current * pagination.pageSize < pagination.total ? (
              <p
                className="text-center cursor-pointer text-blue-500 hover:text-blue-400 p-2 transition-all"
                onClick={() => getOptions(pagination.current + 1)}
              >
                Tampilkan lebih banyak
              </p>
            ) : null}
          </div>
        );
      }}
    />
  );
};

export default SelectWithApi;
