import { useContext, useEffect, useMemo, useState } from "react";
import { HiRefresh, HiUserGroup } from "react-icons/hi";
import {
  BiEdit,
  BiPrinter,
  BiSolidGroup,
  BiSolidPlusCircle,
} from "react-icons/bi";
import { MdMedicalServices } from "react-icons/md";
import { GoHomeFill } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import "dayjs/locale/id";
import { GlobalContext } from "../contexts/GlobalState";
import { getLatestMonths, sortFunction } from "../utils/functions";
import { TypeAntrean, TypeRekamMedis, TypeTableColumn } from "../utils/types";
import {
  GENDER_OPTIONS,
  SIZE_COLUMN_LG,
  SIZE_COLUMN_SM,
  SIZE_COLUMN_XL,
  SIZE_COLUMN_XS,
} from "../utils/constants.tsx";
import { Button, Select, Tooltip as Tooltip2 } from "antd";
import { RekamMedisContext } from "../contexts/RekamMedisState";
import CustomTable from "../components/CustomTable";
import { RuangContext } from "../contexts/RuangState";
import SelectRuangModal from "../components/SelectRuangModal";
import { AntreanContext } from "../contexts/AntreanState";
import AddAntreanModal from "../components/AddAntreanModal";
import { HiMagnifyingGlass } from "react-icons/hi2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const LoadingSpinner: React.FC = () => {
  return (
    <div role="status" className="w-full p-0 flex items-center justify-center">
      <svg
        aria-hidden="true"
        className="w-4 h-4 mr-2 text-white animate-spin fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  );
};

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { summary, isLoadingSummary, getSummary } = useContext(GlobalContext);
  const {
    allRekamMedisList,
    isLoadingGet,
    getRekamMedisByDate,
    printRekamMedis,
  } = useContext(RekamMedisContext);
  const { allRuangList, getAllRuang } = useContext(RuangContext);
  const {
    allAntreanList,
    isLoadingGet: isLoadingGetAntrean,
    getAntreanByDate,
    addAntrean,
    editAntrean,
  } = useContext(AntreanContext);
  useEffect(() => {
    getSummary();
    getAllRuang();
  }, []);
  const allAntreanListFormatted = useMemo(() => {
    const waitingList = allAntreanList.filter(
      (item) => !item.tgl_selesai_diperiksa
    );
    const doneList = allAntreanList.filter(
      (item) => item.tgl_selesai_diperiksa
    );
    return [...waitingList, ...doneList];
  }, [allAntreanList]);

  const data = useMemo(() => {
    const latestMonths = getLatestMonths();
    const labels = latestMonths.map((item) => {
      const parsedDate = dayjs(item).locale("id");
      const formattedDate = parsedDate.format("MMMM YYYY");
      return formattedDate;
    });
    return {
      labels,
      datasets: [
        {
          label: "Laki-laki",
          data: latestMonths.map((item) => {
            const monthlyData = summary.history.find(
              (item2) => item2.formattedDate === item
            );
            if (monthlyData) {
              return monthlyData.maleCount;
            } else {
              return 0;
            }
          }),
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "Perempuan",
          data: latestMonths.map((item) => {
            const monthlyData = summary.history.find(
              (item2) => item2.formattedDate === item
            );
            if (monthlyData) {
              return monthlyData.femaleCount;
            } else {
              return 0;
            }
          }),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
  }, [summary]);

  const options = useMemo(() => {
    let maxVar = 10;
    summary.history.forEach((item) => {
      if (item.femaleCount > maxVar) {
        maxVar = item.femaleCount + 5;
      }
      if (item.maleCount > maxVar) {
        maxVar = item.maleCount + 5;
      }
    });
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top" as const,
        },
      },
      scales: {
        y: {
          suggestedMax: maxVar,
        },
      },
    };
  }, [summary]);

  const rekamMedisColumns: TypeTableColumn<TypeRekamMedis>[] = [
    {
      title: "No",
      dataIndex: "no",
      align: "center",
      width: SIZE_COLUMN_XS,
    },
    {
      title: "No RM",
      dataIndex: "no_rm",
      align: "center",
      width: SIZE_COLUMN_SM,
    },
    {
      title: "Nama Pasien",
      dataIndex: "nama_pasien",
      sorter: sortFunction("nama_pasien"),
      editable: true,
      width: SIZE_COLUMN_XL,
      editIndex: "id_pasien",
    },
    {
      title: "Nama Dokter",
      dataIndex: "nama_dokter",
      sorter: sortFunction("nama_dokter"),
      editable: true,
      width: SIZE_COLUMN_XL,
      editIndex: "id_dokter",
    },
    {
      title: "Perawatan",
      dataIndex: "perawatan",
      editable: true,
      className: "min-w-32",
      withQuantity: true,
    },
    {
      title: "Aksi",
      align: "center",
      render: (text: string, item: TypeRekamMedis) => (
        <div className="flex gap-3 items-center justify-center">
          <Tooltip2 title="Print Nota">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={(e) => {
                e.preventDefault();
                printRekamMedis(item);
              }}
            >
              <BiPrinter size={20} />
            </Button>
          </Tooltip2>
        </div>
      ),
      width: SIZE_COLUMN_LG,
    },
  ];

  const antreanColumns: TypeTableColumn<TypeAntrean>[] = [
    {
      title: "No Antrean",
      dataIndex: "no_antrean",
      align: "center",
      sorter: sortFunction("no_antrean"),
      width: SIZE_COLUMN_SM,
    },
    {
      title: "Waktu Kedatangan",
      dataIndex: "tgl_antrean",
      align: "center",
      sorter: sortFunction("tgl_antrean"),
      editable: true,
    },
    {
      title: "Waktu Diperiksa",
      dataIndex: "tgl_diperiksa",
      align: "center",
      sorter: sortFunction("tgl_diperiksa"),
      editable: true,
      optional: true,
    },
    {
      title: "Waktu Selesai Diperiksa",
      dataIndex: "tgl_selesai_diperiksa",
      align: "center",
      sorter: sortFunction("tgl_selesai_diperiksa"),
      editable: true,
      optional: true,
    },
    {
      title: "No RM",
      dataIndex: "no_rm",
      align: "center",
      width: SIZE_COLUMN_SM,
    },
    {
      title: "Nama Pasien",
      dataIndex: "nama",
      sorter: sortFunction("nama"),
      width: SIZE_COLUMN_XL,
    },
    {
      title: "Jenis Kelamin",
      dataIndex: "jenis_kelamin",
      sorter: sortFunction("jenis_kelamin"),
      width: SIZE_COLUMN_LG,
      columnOptions: GENDER_OPTIONS,
    },
    {
      title: "Alamat",
      dataIndex: "alamat",
      className: "min-w-32",
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan",
      editable: true,
      className: "min-w-32",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   sorter: sortFunction("status"),
    //   width: SIZE_COLUMN_LG,
    //   columnOptions: STATUS_OPTIONS,
    //   editable: true,
    // },
    {
      title: "Aksi",
      align: "center",
      render: (text: string, item: TypeAntrean) => (
        <div className="flex gap-3 items-center justify-center">
          <Tooltip2 title="Edit">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={() => setEditingItem(item)}
            >
              <BiEdit size={20} />
            </Button>
          </Tooltip2>
          <Tooltip2 title="Periksa">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={() => navigate(`/pasien/${item.no_rm}?ref=/`)}
            >
              <HiMagnifyingGlass size={20} />
            </Button>
          </Tooltip2>
        </div>
      ),
      width: SIZE_COLUMN_LG,
    },
  ];

  // Antrean functions
  const [currentRuang, setCurrentRuang] = useState<string>(null);
  const [selectRuangModalOpen, setSelectRuangModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState<TypeAntrean>(null);
  const refreshAntrean = (id_ruang: number) => {
    getAntreanByDate({
      id_ruang,
      start_date: dayjs().startOf("day").toISOString(),
      end_date: dayjs().endOf("day").toISOString(),
    });
    getRekamMedisByDate({
      id_ruang,
      start_date: dayjs().startOf("day").toISOString(),
      end_date: dayjs().endOf("day").toISOString(),
    });
  };
  useEffect(() => {
    if (currentRuang === null) {
      const savedRuang = sessionStorage.getItem("currentRuang");
      if (savedRuang) {
        setCurrentRuang(savedRuang);
      } else {
        if (allRuangList.length) {
          setSelectRuangModalOpen(true);
        }
      }
    } else {
      refreshAntrean(parseInt(currentRuang));
      sessionStorage.setItem("currentRuang", currentRuang);
      setSelectRuangModalOpen(false);
    }
  }, [currentRuang, allRuangList]);
  const [modalAddAntreanOpen, setModalAddAntreanOpen] = useState(false);
  const addData = async (data: TypeAntrean) => {
    const res = await addAntrean({
      ...data,
      start_date: dayjs().startOf("day").toISOString(),
      end_date: dayjs().endOf("day").toISOString(),
    });
    return res;
  };
  const editData = async (data: Partial<TypeAntrean>) => {
    return await editAntrean({ ...data, id: editingItem.id });
  };

  return (
    <div className="w-full flex flex-col p-6 gap-6 items-center">
      <div className="w-full grid gap-6 grid-cols-2 md:grid-cols-4 items-center">
        <Link
          to="/dokter"
          className="rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-4 text-black hover:text-black hover:bg-gray-100 shadow-md transition-all"
        >
          <h3>Dokter</h3>
          <div className="flex justify-start gap-4 items-center">
            <HiUserGroup size={20} />
            <h2>
              {isLoadingSummary ? <LoadingSpinner /> : summary.dokterCount}
            </h2>
          </div>
        </Link>
        <Link
          to="/pasien"
          className="rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-4 text-black hover:text-black hover:bg-gray-100 shadow-md transition-all"
        >
          <h3>Pasien</h3>
          <div className="flex justify-start gap-4 items-center">
            <BiSolidGroup size={20} />
            <h2>
              {isLoadingSummary ? <LoadingSpinner /> : summary.pasienCount}
            </h2>
          </div>
        </Link>
        <Link
          to="/perawatan"
          className="rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-4 text-black hover:text-black hover:bg-gray-100 shadow-md transition-all"
        >
          <h3>Perawatan</h3>
          <div className="flex justify-start gap-4 items-center">
            <MdMedicalServices size={20} />
            <h2>
              {isLoadingSummary ? <LoadingSpinner /> : summary.perawatanCount}
            </h2>
          </div>
        </Link>
        <Link
          to="/ruang"
          className="rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-4 text-black hover:text-black hover:bg-gray-100 shadow-md transition-all"
        >
          <h3>Klinik</h3>
          <div className="flex justify-start gap-4 items-center">
            <GoHomeFill size={20} />
            <h2>
              {isLoadingSummary ? <LoadingSpinner /> : summary.ruangCount}
            </h2>
          </div>
        </Link>
      </div>

      <div className="flex justify-end items-center gap-3">
        <h3>Klinik : </h3>
        <Select
          showSearch
          placeholder={`Pilih klinik`}
          optionFilterProp="children"
          filterOption={(input: string, option: any) => {
            return (option?.label?.props?.children ?? "")
              .toLowerCase()
              .includes((input || "").toLowerCase());
          }}
          onChange={setCurrentRuang}
          value={currentRuang}
          className="select-fit-content min-w-40"
          options={allRuangList.map((item) => ({
            label: item.nama,
            value: String(item.id),
          }))}
        />
      </div>

      <div className="w-full rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-6 shadow-md transition-all">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-2">
            <h3>Antrean Hari Ini</h3>

            <Tooltip2 title="Refresh">
              <HiRefresh
                onClick={() => refreshAntrean(parseInt(currentRuang))}
                className="cursor-pointer text-gray-400 hover:text-gray-300 transition-all"
                size={20}
              />
            </Tooltip2>
          </div>

          <Button
            type="primary"
            className="flex gap-4 items-center"
            onClick={() => setModalAddAntreanOpen(true)}
            disabled={currentRuang === null}
          >
            <BiSolidPlusCircle size={20} /> Tambah antrean
          </Button>
        </div>

        <CustomTable<TypeAntrean>
          pagination={null}
          dataList={allAntreanListFormatted}
          columns={antreanColumns}
          isLoading={isLoadingGetAntrean}
          setFilter={null}
          filter={null}
          editingItem={editingItem}
          setEditingItem={setEditingItem}
          editData={editData}
        />
      </div>
      <div className="w-full rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-6 shadow-md transition-all">
        <h3>Pasien Selesai Hari Ini</h3>

        <CustomTable<TypeRekamMedis>
          pagination={null}
          dataList={allRekamMedisList}
          columns={rekamMedisColumns}
          isLoading={isLoadingGet}
          setFilter={null}
          filter={null}
          editingItem={null}
          setEditingItem={null}
          editData={null}
        />
      </div>

      <div className="w-full rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-4 shadow-md transition-all">
        <h3>Pasien Per Bulan</h3>
        <Bar data={data} options={options} />
      </div>
      <SelectRuangModal
        modalOpen={selectRuangModalOpen}
        setModalOpen={setSelectRuangModalOpen}
        allRuangList={allRuangList}
        setCurrentRuang={setCurrentRuang}
      />
      {/* eslint-disable */}
      <AddAntreanModal
        currentRuang={currentRuang}
        modalOpen={modalAddAntreanOpen}
        setModalOpen={setModalAddAntreanOpen}
        addData={addData}
      />
      {/* eslint-enable */}
    </div>
  );
};

export default Home;
