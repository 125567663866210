import { useContext, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { GlobalContext } from "../contexts/GlobalState";
import { Button, DatePicker, Form, Input, Modal, Radio, Select } from "antd";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  FAILED_DISPLAY_MESSAGE,
  REQUIRED_MESSAGE,
  dateColumns,
  dateTimeColumns,
  floatColumns,
  noSpaceColumns,
  numberColumns,
  passwordColumns,
  radioColumns,
  textareaColumns,
} from "../utils/constants.tsx";
import { generateArray } from "../utils/functions";
import { TypeTableColumn } from "../utils/types.ts";

interface TypeAddModal<A> {
  modalOpen: boolean;
  columns: TypeTableColumn<A>[];
  title: string;
  addData: (values: any) => Promise<any>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddDataModal = <A,>({
  modalOpen,
  columns,
  title,
  addData,
  setModalOpen,
}: TypeAddModal<A>) => {
  const { showLoadingMessage, showSuccessMessage, showErrorMessage } =
    useContext(GlobalContext);

  const [addForm] = useForm();
  const onSubmitForm = async (values) => {
    const messageKey = new Date().toISOString();
    showLoadingMessage(messageKey);
    const res = await addData(values);
    if (res?.success) {
      showSuccessMessage(messageKey, "Data berhasil ditambahkan");
      setModalOpen(false);
      addForm.resetFields();
      setQuantityState(
        columns
          .filter((item) => item.withQuantity)
          .map((item) => ({
            dataIndex: item.dataIndex as string,
            quantity: 1,
          }))
      );
    } else {
      showErrorMessage(
        messageKey,
        res?.displayMessage || FAILED_DISPLAY_MESSAGE
      );
    }
  };

  const [quantityState, setQuantityState] = useState<
    {
      dataIndex: string;
      quantity: number;
    }[]
  >(
    columns
      .filter((item) => item.withQuantity)
      .map((item) => ({
        dataIndex: item.dataIndex as string,
        quantity: 1,
      }))
  );

  const handleAddField = (dataIndex: string) => {
    setQuantityState((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((item) => item.dataIndex === dataIndex);
      newState[index].quantity += 1;
      return newState;
    });
  };

  const handleRemoveField = (
    dataIndex: string,
    itemIndex: number,
    suffixList: string[]
  ) => {
    setQuantityState((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((item) => item.dataIndex === dataIndex);
      const prevQuantity = prevState[index].quantity;
      const newQuantity = prevState[index].quantity - 1;
      newState[index].quantity = newQuantity;

      const prevDataFields = [];
      generateArray(prevQuantity).forEach((item2, index2) => {
        suffixList.forEach((suffix) => {
          prevDataFields.push(`${dataIndex}${suffix}[${index2}]`);
        });
      });

      const prevData = addForm.getFieldsValue();
      const newData = {};

      suffixList.forEach((suffix) => {
        newData[`${dataIndex}${suffix}`] = [];
      });

      generateArray(newQuantity).forEach((item2, index2) => {
        if (index2 < itemIndex) {
          suffixList.forEach((suffix) => {
            newData[`${dataIndex}${suffix}`].push(
              prevData[`${dataIndex}${suffix}`][index2]
            );
          });
        } else {
          suffixList.forEach((suffix) => {
            newData[`${dataIndex}${suffix}`].push(
              prevData[`${dataIndex}${suffix}`][index2 + 1]
            );
          });
        }
      });

      addForm.resetFields(prevDataFields);

      const newFieldsValue = {};
      suffixList.forEach((suffix) => {
        newFieldsValue[`${dataIndex}${suffix}`] =
          newData[`${dataIndex}${suffix}`];
      });
      addForm.setFieldsValue(newFieldsValue);

      return newState;
    });
  };

  return (
    <Modal
      title={title}
      open={modalOpen}
      okText="Tambah"
      onOk={() => addForm.submit()}
      onCancel={() => setModalOpen(false)}
      className="max-w-none !w-11/12 md:!w-2/3 !p-4 flex flex-col gap-3"
      centered
    >
      <Form
        form={addForm}
        className="w-full flex p-4 flex-col gap-3"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onSubmitForm}
      >
        {columns.map((item, index) => {
          if (item.withQuantity) {
            const quantity = quantityState.find(
              (item2) => item2.dataIndex === item.dataIndex
            ).quantity;
            const quantityList = generateArray(quantity);
            return (
              <div
                className="w-full flex flex-col items-start gap-2"
                key={index}
              >
                <p>{item.title as string}</p>
                <div className="flex flex-col gap-2 w-full">
                  <item.CustomComponentEditorRender
                    item={item}
                    quantityList={quantityList}
                    handleRemoveField={handleRemoveField}
                    addForm={addForm}
                  />
                </div>
                <Button
                  type="primary"
                  onClick={() => handleAddField(item.dataIndex as string)}
                >
                  Tambah
                </Button>
              </div>
            );
          }

          if (item.CustomComponentEditorRender) {
            return (
              <div
                className="w-full flex flex-col items-start gap-2"
                key={index}
              >
                <p>{item.title as string}</p>
                <div className="flex flex-col gap-2 w-full">
                  <item.CustomComponentEditorRender
                    item={item}
                    quantityList={[]}
                    handleRemoveField={null}
                    addForm={addForm}
                  />
                </div>
              </div>
            );
          }

          return (
            <div className="w-full flex flex-col gap-3" key={index}>
              <Form.Item
                key={index}
                rules={[
                  {
                    required: !item.optional,
                    message: REQUIRED_MESSAGE,
                  },
                ]}
                label={item.title as string}
                name={(item.editIndex || item.dataIndex) as string}
                className="m-0"
              >
                {numberColumns.includes(item.dataIndex as string) ? (
                  <Input
                    onChange={(e) => {
                      const value = e.target.value;
                      addForm.setFieldValue(
                        item.dataIndex,
                        value.replace(/[^0-9]/g, "")
                      );
                    }}
                  />
                ) : floatColumns.includes(item.dataIndex as string) ? (
                  <Input
                    onChange={(e) => {
                      const value = e.target.value;
                      addForm.setFieldValue(
                        item.dataIndex,
                        value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
                      );
                    }}
                  />
                ) : dateColumns.includes(item.dataIndex as string) ? (
                  <DatePicker
                    placeholder={DATE_FORMAT}
                    className="w-full"
                    format={DATE_FORMAT}
                  />
                ) : dateTimeColumns.includes(item.dataIndex as string) ? (
                  <DatePicker
                    showTime
                    placeholder={DATETIME_FORMAT}
                    className="w-full"
                    format={DATETIME_FORMAT}
                  />
                ) : radioColumns.includes(item.dataIndex as string) ? (
                  <Radio.Group options={item.columnOptions} />
                ) : textareaColumns.includes(item.dataIndex as string) ? (
                  <Input.TextArea
                    className="w-full"
                    autoSize={{ minRows: 3 }}
                    maxLength={1000}
                  />
                ) : passwordColumns.includes(item.dataIndex as string) ? (
                  <Input.Password className="w-full" />
                ) : item.editSelectItems && !item.withQuantity ? (
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(
                      input: string,
                      option: { label: string; value: string }
                    ) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes((input ?? "").toLowerCase())
                    }
                    options={item.editSelectItems}
                  />
                ) : noSpaceColumns.includes(item.dataIndex as string) ? (
                  <Input
                    onChange={(e) => {
                      const value = (e.target?.value || "").replaceAll(" ", "");
                      addForm.setFieldValue("username", value);
                    }}
                  />
                ) : (
                  <Input />
                )}
              </Form.Item>
              {passwordColumns.includes(item.dataIndex as string) ? (
                <Form.Item
                  label="Konfirmasi Password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  className="m-0"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGE,
                    },
                    {
                      validator: (_, value) => {
                        const password = addForm.getFieldValue("password");
                        if (password === value) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Password tidak cocok");
                        }
                      },
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              ) : null}
            </div>
          );
        })}
      </Form>
    </Modal>
  );
};

export default AddDataModal;
