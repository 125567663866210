import { useState, useContext, useEffect } from "react";
import { FaBriefcaseMedical } from "react-icons/fa6";
import { Button, Input, Tooltip, Modal, Row, Form } from "antd";
import { debounce } from "lodash";
import { PerawatanContext } from "../contexts/PerawatanState";
import {
  TypePerawatan,
  TypeFilterTable,
  TypeTableColumn,
  TypeKodePerawatan,
} from "../utils/types";
import CustomTable from "../components/CustomTable";
import { BiEdit, BiTrash } from "react-icons/bi";
import { GlobalContext } from "../contexts/GlobalState";
import {
  FAILED_DISPLAY_MESSAGE,
  SIZE_COLUMN_LG,
  SIZE_COLUMN_XL,
  SIZE_COLUMN_XS,
} from "../utils/constants.tsx";
import AddDataModal from "../components/AddDataModal";
import { filteredColumns } from "../utils/functions";
import SelectWithApi from "../components/SelectWithApi.tsx";

const { Search } = Input;
const { confirm } = Modal;

const Perawatan: React.FC = () => {
  const [componentDidMount, setComponentDidMount] = useState(false);
  const { user, showLoadingMessage, showSuccessMessage, showErrorMessage } =
    useContext(GlobalContext);
  const {
    perawatanList,
    pagination,
    isLoadingGet,
    getPerawatan,
    addPerawatan,
    editPerawatan,
    deletePerawatan,
    getKodePerawatan,
  } = useContext(PerawatanContext);
  const [filter, setFilter] = useState<TypeFilterTable<TypePerawatan>>({
    page: 1,
    sortField: "nama",
    isAsc: true,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const getData = (page?: number) => {
    getPerawatan({
      page: page || filter.page,
      query: searchQuery,
      sort_field: filter.sortField,
      is_asc: filter.isAsc,
    });
  };
  const getDataDebounce = debounce((e) => {
    setSearchQuery(e?.target?.value);
  }, 500);

  // Perform get data
  useEffect(() => {
    getData();
    setComponentDidMount(true);
  }, []);

  useEffect(() => {
    if (componentDidMount) {
      getData();
    }
  }, [filter]);

  useEffect(() => {
    if (componentDidMount) {
      getData(1);
    }
  }, [searchQuery]);

  const [editingItem, setEditingItem] = useState<TypePerawatan>(null);

  const perawatanColumns: TypeTableColumn<TypePerawatan>[] = [
    {
      title: "No",
      dataIndex: "no",
      align: "center",
      width: SIZE_COLUMN_XS,
    },
    {
      title: "Nama",
      dataIndex: "nama",
      sorter: true,
      editable: true,
    },
    {
      title: "Harga",
      dataIndex: "harga",
      sorter: true,
      editable: true,
      width: SIZE_COLUMN_XL,
    },
    {
      title: "Kode Perawatan",
      dataIndex: "nama_kode_perawatan",
      editIndex: "id_kode_perawatan",
      editable: true,
      hidden: true,
      optional: true,
      CustomComponentEditorRender: ({
        item,
        handleRemoveField,
        addForm,
        quantityList,
        initialValue,
        record,
      }) => {
        let initialOptions = [];
        if (record) {
          addForm.setFields([
            {
              name: item.editIndex || item.dataIndex,
              errors: [],
            },
          ]);
          addForm.setFieldValue(
            item.editIndex || item.dataIndex,
            record[(item.editIndex || item.dataIndex) as string]
          );
          initialOptions = [
            {
              label: record.nama_kode_perawatan,
              value: record.id_kode_perawatan,
            },
          ];
        }

        return (
          <Row className="flex gap-2 w-full items-start">
            <Form.Item
              className="m-0 w-full"
              name={(item.editIndex || item.dataIndex) as string}
              // rules={[
              //   {
              //     required: true,
              //     message: REQUIRED_MESSAGE,
              //   },
              // ]}
            >
              <SelectWithApi<TypeKodePerawatan[]>
                fetchOptions={async function (query: string, page: number) {
                  return await getKodePerawatan({ query, page });
                }}
                transformData={(data) =>
                  data.map((item2) => ({
                    label: `${item2.kode} - ${item2.nama}`,
                    value: item2.id.toString(),
                  }))
                }
                initialOptions={initialOptions}
                onChange={(value) => {
                  addForm.setFieldValue(
                    item.editIndex || item.dataIndex,
                    value
                  );
                }}
                placeholder="Pilih kode perawatan"
              />
            </Form.Item>
          </Row>
        );
      },
    },
    {
      title: "Aksi",
      align: "center",
      render: (text: string, item: TypePerawatan) => (
        <div className="flex gap-3 items-center justify-center">
          <Tooltip title="Edit">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={() => setEditingItem(item)}
            >
              <BiEdit size={20} />
            </Button>
          </Tooltip>
          <Tooltip title="Hapus">
            <Button
              type="primary"
              className="flex items-center justify-center p-1"
              onClick={(e) => {
                e.preventDefault();
                confirm({
                  title: `Apakah Anda yakin ingin menghapus perawatan : ${item.nama}?`,
                  icon: null,
                  content:
                    "Data yang sudah dihapus tidak dapat dikembalikan lagi.",
                  okButtonProps: { type: "primary", danger: true },
                  okText: "Hapus",
                  cancelButtonProps: { type: "primary" },
                  cancelText: "Batal",
                  onOk() {
                    deleteData(item.id);
                  },
                  maskClosable: true,
                });
              }}
              danger
            >
              <BiTrash size={20} />
            </Button>
          </Tooltip>
        </div>
      ),
      width: SIZE_COLUMN_LG,
    },
  ];

  const addData = async (data: TypePerawatan) => {
    const res = await addPerawatan(data);
    if (res?.data) {
      getData();
    }
    return res;
  };
  const editData = async (data: Partial<TypePerawatan>) => {
    return await editPerawatan({ ...data, id: editingItem.id });
  };
  const deleteData = async (id: number) => {
    const messageKey = new Date().toISOString();
    showLoadingMessage(messageKey);
    const res = await deletePerawatan(id);
    if (res?.success) {
      showSuccessMessage(messageKey, "Data berhasil dihapus");
    } else {
      showErrorMessage(
        messageKey,
        res.displayMessage || FAILED_DISPLAY_MESSAGE
      );
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className="w-full flex flex-col p-6 gap-6 items-center">
      <div className="w-full rounded-lg bg-white overflow-hidden flex p-4 flex-col gap-4 shadow-md transition-all">
        <h3>Data Perawatan</h3>
        <div className="w-full flex justify-between items-center gap-4">
          <div className="flex gap-4 items-center">
            <Search
              placeholder="Cari perawatan"
              onChange={getDataDebounce}
              className="w-auto md:w-96"
            />
          </div>
          {user?.role === "SUPERADMIN" ? (
            <Button
              type="primary"
              className="flex items-center gap-2"
              onClick={() => setModalOpen(true)}
            >
              <FaBriefcaseMedical size={20} />
              Tambah Perawatan
            </Button>
          ) : null}
        </div>
        <CustomTable<TypePerawatan>
          pagination={pagination}
          dataList={perawatanList}
          columns={filteredColumns(user, perawatanColumns)}
          isLoading={isLoadingGet}
          setFilter={setFilter}
          filter={filter}
          editingItem={editingItem}
          setEditingItem={setEditingItem}
          editData={editData}
        />
      </div>
      <AddDataModal<TypePerawatan>
        title="Tambah Perawatan"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        columns={perawatanColumns.filter((item) => item.editable)}
        addData={addData}
      />
    </div>
  );
};

export default Perawatan;
